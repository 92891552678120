var quizQuestions = [
    {
        question: "My social circle is:",
        answers: [
            {
                type: "Extraversion_Outgoing-Energetic_0-0",
                content: "Large and diverse"
            },
            {
                type: "Extraversion_Solitary-Reserved_0-1",
                content: "Small and close-knit"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_0-2",
                content: "Professional connections"
            },
            {
                type: "Agreeableness_Critical-Judgmental_0-3",
                content: "Family"
            }
        ]
    },
    {
        question: "When faced with a new problem I:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_1-0",
                content: "Dive in and start solving it"
            },
            {
                type: "Conscientiousness_Efficient-Organized_1-1",
                content: "Carefully plan my approach"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_1-2",
                content: "Seek advice from others"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_1-3",
                content: "Feel anxious and unsure"
            }
        ]
    },
    {
        question: "In a group project I prefer to:",
        answers: [
            {
                type: "Extraversion_Outgoing-Energetic_2-0",
                content: "Take the lead"
            },
            {
                type: "Conscientiousness_Efficient-Organized_2-1",
                content: "Follow instructions"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_2-2",
                content: "Support others"
            },
            {
                type: "Extraversion_Solitary-Reserved_2-3",
                content: "Stay in the background"
            }
        ]
    },
    {
        question: "My idea of a perfect weekend is:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_3-0",
                content: "Exploring new places"
            },
            {
                type: "Extraversion_Solitary-Reserved_3-1",
                content: "Relaxing at home"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_3-2",
                content: "Spending time with friends"
            },
            {
                type: "Conscientiousness_Efficient-Organized_3-3",
                content: "Catching up on work"
            }
        ]
    },
    {
        question: "When it comes to my personal belongings I am:",
        answers: [
            {
                type: "Conscientiousness_Efficient-Organized_4-0",
                content: "Very organized"
            },
            {
                type: "Conscientiousness_Efficient-Organized_4-1",
                content: "Somewhat organized"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_4-2",
                content: "Not very organized"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_4-3",
                content: "Not organized at all"
            }
        ]
    },
    {
        question: "In social situations I am:",
        answers: [
            {
                type: "Extraversion_Outgoing-Energetic_5-0",
                content: "The life of the party"
            },
            {
                type: "Extraversion_Outgoing-Energetic_5-1",
                content: "Comfortable but reserved"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_5-2",
                content: "A good listener"
            },
            {
                type: "Extraversion_Solitary-Reserved_5-3",
                content: "Quiet and withdrawn"
            }
        ]
    },
    {
        question: "When making decisions I:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_6-0",
                content: "Trust my intuition"
            },
            {
                type: "Conscientiousness_Efficient-Organized_6-1",
                content: "Weigh all options carefully"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_6-2",
                content: "Consult with others"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_6-3",
                content: "Feel indecisive"
            }
        ]
    },
    {
        question: "When I encounter stress I:",
        answers: [
            {
                type: "Neuroticism_Resilient-Confident_7-0",
                content: "Handle it well"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_7-1",
                content: "Manage it with some difficulty"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_7-2",
                content: "Feel very stressed"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_7-3",
                content: "Panic"
            }
        ]
    },
    {
        question: "My approach to new experiences is:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_8-0",
                content: "Very open"
            },
            {
                type: "Openness to Experience_Inventive-Curious_8-1",
                content: "Somewhat open"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_8-2",
                content: "Cautious"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_8-3",
                content: "Resistant"
            }
        ]
    },
    {
        question: "When working on a project I:",
        answers: [
            {
                type: "Conscientiousness_Efficient-Organized_9-0",
                content: "Stay focused and on task"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_9-1",
                content: "Take frequent breaks"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_9-2",
                content: "Collaborate with others"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_9-3",
                content: "Struggle to stay focused"
            }
        ]
    },
    {
        question: "In a conversation I usually:",
        answers: [
            {
                type: "Extraversion_Outgoing-Energetic_10-0",
                content: "Speak a lot"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_10-1",
                content: "Listen more than I speak"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_10-2",
                content: "Balance speaking and listening"
            },
            {
                type: "Extraversion_Solitary-Reserved_10-3",
                content: "Stay quiet"
            }
        ]
    },
    {
        question: "When it comes to helping others I am:",
        answers: [
            {
                type: "Agreeableness_Friendly-Compassionate_11-0",
                content: "Always willing"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_11-1",
                content: "Willing if asked"
            },
            {
                type: "Agreeableness_Critical-Judgmental_11-2",
                content: "Occasionally helpful"
            },
            {
                type: "Agreeableness_Critical-Judgmental_11-3",
                content: "Rarely helpful"
            }
        ]
    },
    {
        question: "When dealing with change I:",
        answers: [
            {
                type: "Neuroticism_Resilient-Confident_12-0",
                content: "Adapt easily"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_12-1",
                content: "Adapt with some difficulty"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_12-2",
                content: "Resist change"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_12-3",
                content: "Feel overwhelmed"
            }
        ]
    },
    {
        question: "My approach to learning new things is:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_13-0",
                content: "Very enthusiastic"
            },
            {
                type: "Openness to Experience_Inventive-Curious_13-1",
                content: "Somewhat interested"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_13-2",
                content: "Reluctant"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_13-3",
                content: "Avoidant"
            }
        ]
    },
    {
        question: "When it comes to completing tasks I am:",
        answers: [
            {
                type: "Conscientiousness_Efficient-Organized_14-0",
                content: "Very diligent"
            },
            {
                type: "Conscientiousness_Efficient-Organized_14-1",
                content: "Somewhat diligent"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_14-2",
                content: "Occasionally diligent"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_14-3",
                content: "Not diligent at all"
            }
        ]
    },
    {
        question: "In a team setting I:",
        answers: [
            {
                type: "Extraversion_Outgoing-Energetic_15-0",
                content: "Take charge"
            },
            {
                type: "Conscientiousness_Efficient-Organized_15-1",
                content: "Contribute steadily"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_15-2",
                content: "Support from the sidelines"
            },
            {
                type: "Extraversion_Solitary-Reserved_15-3",
                content: "Prefer to work alone"
            }
        ]
    },
    {
        question: "When faced with a difficult situation I:",
        answers: [
            {
                type: "Neuroticism_Resilient-Confident_16-0",
                content: "Stay calm and collected"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_16-1",
                content: "Feel slightly stressed"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_16-2",
                content: "Feel very stressed"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_16-3",
                content: "Feel overwhelmed"
            }
        ]
    },
    {
        question: "My creative pursuits are:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_17-0",
                content: "Very frequent"
            },
            {
                type: "Openness to Experience_Inventive-Curious_17-1",
                content: "Occasional"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_17-2",
                content: "Rare"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_17-3",
                content: "Nonexistent"
            }
        ]
    },
    {
        question: "When it comes to organizing events I am:",
        answers: [
            {
                type: "Conscientiousness_Efficient-Organized_18-0",
                content: "Very proactive"
            },
            {
                type: "Conscientiousness_Efficient-Organized_18-1",
                content: "Somewhat involved"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_18-2",
                content: "Only involved if necessary"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_18-3",
                content: "Not involved at all"
            }
        ]
    },
    {
        question: "In my daily routine I am:",
        answers: [
            {
                type: "Conscientiousness_Efficient-Organized_19-0",
                content: "Very structured"
            },
            {
                type: "Conscientiousness_Efficient-Organized_19-1",
                content: "Somewhat structured"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_19-2",
                content: "Flexible"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_19-3",
                content: "Unstructured"
            }
        ]
    },
    {
        question: "My communication style is:",
        answers: [
            {
                type: "Extraversion_Outgoing-Energetic_20-0",
                content: "Direct and straightforward"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_20-1",
                content: "Polite and considerate"
            },
            {
                type: "Extraversion_Solitary-Reserved_20-2",
                content: "Reserved and concise"
            },
            {
                type: "Conscientiousness_Efficient-Organized_20-3",
                content: "Detailed and thorough"
            }
        ]
    },
    {
        question: "When faced with a new opportunity I:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_21-0",
                content: "Jump in enthusiastically"
            },
            {
                type: "Conscientiousness_Efficient-Organized_21-1",
                content: "Evaluate carefully"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_21-2",
                content: "Seek advice"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_21-3",
                content: "Feel hesitant"
            }
        ]
    },
    {
        question: "In a social setting I tend to:",
        answers: [
            {
                type: "Extraversion_Outgoing-Energetic_22-0",
                content: "Be very talkative"
            },
            {
                type: "Openness to Experience_Inventive-Curious_22-1",
                content: "Engage in deep conversations"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_22-2",
                content: "Be a good listener"
            },
            {
                type: "Extraversion_Solitary-Reserved_22-3",
                content: "Stay on the sidelines"
            }
        ]
    },
    {
        question: "My work style can be described as:",
        answers: [
            {
                type: "Conscientiousness_Efficient-Organized_23-0",
                content: "Highly organized"
            },
            {
                type: "Openness to Experience_Inventive-Curious_23-1",
                content: "Flexible and adaptive"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_23-2",
                content: "Collaborative"
            },
            {
                type: "Extraversion_Solitary-Reserved_23-3",
                content: "Independent"
            }
        ]
    },
    {
        question: "When giving feedback I am:",
        answers: [
            {
                type: "Agreeableness_Friendly-Compassionate_24-0",
                content: "Constructive and positive"
            },
            {
                type: "Conscientiousness_Efficient-Organized_24-1",
                content: "Honest and direct"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_24-2",
                content: "Tactful and considerate"
            },
            {
                type: "Agreeableness_Critical-Judgmental_24-3",
                content: "Critical and detailed"
            }
        ]
    },
    {
        question: "When planning a trip I:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_25-0",
                content: "Love to explore new places"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_25-1",
                content: "Prefer well-known destinations"
            },
            {
                type: "Conscientiousness_Efficient-Organized_25-2",
                content: "Plan every detail"
            },
            {
                type: "Openness to Experience_Inventive-Curious_25-3",
                content: "Go with the flow"
            }
        ]
    },
    {
        question: "In stressful situations I:",
        answers: [
            {
                type: "Neuroticism_Resilient-Confident_26-0",
                content: "Remain calm and composed"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_26-1",
                content: "Feel slightly anxious"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_26-2",
                content: "Get very stressed"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_26-3",
                content: "Feel overwhelmed"
            }
        ]
    },
    {
        question: "When I think about my future I am:",
        answers: [
            {
                type: "Neuroticism_Resilient-Confident_27-0",
                content: "Optimistic and hopeful"
            },
            {
                type: "Conscientiousness_Efficient-Organized_27-1",
                content: "Practical and realistic"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_27-2",
                content: "Anxious and worried"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_27-3",
                content: "Uncertain and doubtful"
            }
        ]
    },
    {
        question: "When making plans I prefer:",
        answers: [
            {
                type: "Conscientiousness_Efficient-Organized_28-0",
                content: "Detailed and structured plans"
            },
            {
                type: "Openness to Experience_Inventive-Curious_28-1",
                content: "Flexible and open-ended plans"
            },
            {
                type: "Extraversion_Outgoing-Energetic_28-2",
                content: "Spontaneous decisions"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_28-3",
                content: "Avoiding plans altogether"
            }
        ]
    },
    {
        question: "When it comes to new technologies I:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_29-0",
                content: "Embrace them eagerly"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_29-1",
                content: "Adopt them gradually"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_29-2",
                content: "Feel indifferent"
            },
            {
                type: "Openness to Experience_Consistent-Cautious_29-3",
                content: "Avoid them"
            }
        ]
    },
    {
        question: "My approach to problem-solving is:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_30-0",
                content: "Creative and innovative"
            },
            {
                type: "Conscientiousness_Efficient-Organized_30-1",
                content: "Logical and methodical"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_30-2",
                content: "Collaborative and inclusive"
            },
            {
                type: "Conscientiousness_Efficient-Organized_30-3",
                content: "Cautious and thorough"
            }
        ]
    },
    {
        question: "In a conflict I am likely to:",
        answers: [
            {
                type: "Agreeableness_Friendly-Compassionate_31-0",
                content: "Seek a compromise"
            },
            {
                type: "Agreeableness_Critical-Judgmental_31-1",
                content: "Stand my ground"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_31-2",
                content: "Avoid confrontation"
            },
            {
                type: "Extraversion_Outgoing-Energetic_31-3",
                content: "Address it directly"
            }
        ]
    },
    {
        question: "When working on long-term projects I:",
        answers: [
            {
                type: "Conscientiousness_Efficient-Organized_32-0",
                content: "Maintain focus and dedication"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_32-1",
                content: "Take breaks to recharge"
            },
            {
                type: "Agreeableness_Friendly-Compassionate_32-2",
                content: "Seek input from others"
            },
            {
                type: "Conscientiousness_Extravagant-Careless_32-3",
                content: "Struggle to stay motivated"
            }
        ]
    },
    {
        question: "When meeting new people I feel:",
        answers: [
            {
                type: "Extraversion_Outgoing-Energetic_33-0",
                content: "Excited and energized"
            },
            {
                type: "Openness to Experience_Inventive-Curious_33-1",
                content: "Curious and interested"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_33-2",
                content: "Nervous but willing"
            },
            {
                type: "Extraversion_Solitary-Reserved_33-3",
                content: "Shy and reserved"
            }
        ]
    },
    {
        question: "My reaction to unexpected news is:",
        answers: [
            {
                type: "Neuroticism_Resilient-Confident_34-0",
                content: "Stay calm and think it through"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_34-1",
                content: "Feel a bit anxious but manage"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_34-2",
                content: "Get very stressed"
            },
            {
                type: "Neuroticism_Sensitive-Nervous_34-3",
                content: "Panic and feel overwhelmed"
            }
        ]
    },
    {
        question: "In my free time I prefer to:",
        answers: [
            {
                type: "Openness to Experience_Inventive-Curious_35-0",
                content: "Engage in creative activities"
            },
            {
                type: "Conscientiousness_Efficient-Organized_35-1",
                content: "Organize and plan future tasks"
            },
            {
                type: "Extraversion_Outgoing-Energetic_35-2",
                content: "Socialize with friends"
            },
            {
                type: "Neuroticism_Resilient-Confident_35-3",
                content: "Relax and unwind"
            }
        ]
    }
];

export default quizQuestions;
